import React, { useMemo } from 'react';
import { PickerOverlay } from 'filestack-react';

import { ReactFilestackProps } from './types';
import { useDialog } from '../../../hooks';

const ReactFilestack: React.FC<ReactFilestackProps> = (props) => {
  const { render, apikey, pickerOptions, onError, onUploadDone } = props;

  const { open, openDialog, closeDialog } = useDialog();

  const options = useMemo(
    () => ({
      ...pickerOptions,
      onClose: closeDialog,
    }),
    [closeDialog, pickerOptions],
  );

  return (
    <>
      {render({ onPick: openDialog })}
      {open && (
        <PickerOverlay
          apikey={apikey}
          pickerOptions={options}
          onUploadDone={onUploadDone}
          onError={onError}
          clientOptions={{
            forwardErrors: true,
          }}
        />
      )}
    </>
  );
};

export default ReactFilestack;
