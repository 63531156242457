// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
html {
  scrollbar-width: thin; !* FF definition to scroll bar width *!
  font-family: "Adelle-sans", "Roboto", sans-serif;
  font-size: 16px;
}

html ::-webkit-scrollbar-track {
  !*-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);*!
  border-radius: 10px;
  background-color: #F5F5F5;
}

html ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #F5F5F5;
}

html ::-webkit-scrollbar-thumb {
  border-radius: 6px;
  !*-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);*!
  background-color: #818293;
}
*/
@viewport {
  zoom: 1;
  width: extend-to-zoom auto;
}
body {
  background-color: #ECEBEB;
  font-family: "Adelle", "Roboto", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Adelle", "Roboto", sans-serif;
}
/*
:global(.ReactTable .rt-table) {
  flex: auto !important;
}
*/
/*
img {
  max-width: 100%;
  max-height: 100%;
}

a {
  text-decoration: none;
  color: #534FF2;
}

a:hover {
  text-decoration: underline;
}

:global(.dashboard-updates) {
  padding: 5px 10px;
  margin-left: 90px;
  height: 90px;
}
*/
:global(.m-b-16) {
  margin-bottom: 16px;
}
:global(.p-8) {
  padding: 8px;
}
.pagination-bottom {
  height: 70px;
}
/*
@media (prefers-color-scheme: dark) {
  .dark {
    a {
      color: #E8E5E3;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    img {
      opacity: .75;
      transition: opacity .5s ease-in-out;

      &:hover {
        opacity: 1;
      }
    }
  }
}
*/
`, "",{"version":3,"sources":["webpack://./src/client/style.less"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;CAwBC;AAED;EACE,OAAA;EACA,0BAAA;AAAF;AAGA;EACE,yBAAA;EACA,2CAAA;AADF;AAIA;;;;;;EACE,2CAAA;AAGF;AACA;;;;CAIC;AACD;;;;;;;;;;;;;;;;;;;;CAoBC;AAGD;EACE,mBAAA;AADF;AAIA;EACE,YAAA;AAFF;AAKA;EACE,YAAA;AAHF;AACA;;;;;;;;;;;;;;;;;;;;;;CAsBC","sourcesContent":["/*\nhtml {\n  scrollbar-width: thin; !* FF definition to scroll bar width *!\n  font-family: \"Adelle-sans\", \"Roboto\", sans-serif;\n  font-size: 16px;\n}\n\nhtml ::-webkit-scrollbar-track {\n  !*-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);*!\n  border-radius: 10px;\n  background-color: #F5F5F5;\n}\n\nhtml ::-webkit-scrollbar {\n  width: 8px;\n  height: 8px;\n  background-color: #F5F5F5;\n}\n\nhtml ::-webkit-scrollbar-thumb {\n  border-radius: 6px;\n  !*-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);*!\n  background-color: #818293;\n}\n*/\n\n@viewport {\n  zoom: 1.0;\n  width: extend-to-zoom auto;\n}\n\nbody {\n  background-color: #ECEBEB;\n  font-family: \"Adelle\", \"Roboto\", sans-serif;\n}\n\nh1, h2, h3, h4, h5, h6 {\n  font-family: \"Adelle\", \"Roboto\", sans-serif;\n}\n\n\n/*\n:global(.ReactTable .rt-table) {\n  flex: auto !important;\n}\n*/\n\n/*\nimg {\n  max-width: 100%;\n  max-height: 100%;\n}\n\na {\n  text-decoration: none;\n  color: #534FF2;\n}\n\na:hover {\n  text-decoration: underline;\n}\n\n:global(.dashboard-updates) {\n  padding: 5px 10px;\n  margin-left: 90px;\n  height: 90px;\n}\n*/\n\n:global(.m-b-16) {\n  margin-bottom: 16px;\n}\n\n:global(.p-8) {\n  padding: 8px;\n}\n\n.pagination-bottom {\n  height: 70px;\n}\n\n/*\n@media (prefers-color-scheme: dark) {\n  .dark {\n    a {\n      color: #E8E5E3;\n      text-decoration: none;\n\n      &:hover {\n        text-decoration: underline;\n      }\n    }\n\n    img {\n      opacity: .75;\n      transition: opacity .5s ease-in-out;\n\n      &:hover {\n        opacity: 1;\n      }\n    }\n  }\n}\n*/\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
