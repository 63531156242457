import mergeWith from 'lodash/mergeWith';

// Returns the best object, merged out of several. Best means picking the best value for each key from each object,
// preferring values over null
// eslint-disable-next-line import/prefer-default-export
export function mergeObjectsToBestObject(arrayOfObjects: Array<Record<string, any>>): Record<string, any> {
  return arrayOfObjects.reduce(
    (mergedObject, currentObject) =>
      mergeWith(
        mergedObject,
        currentObject,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (firstValue, secondValue) => {
          // By returning undefined, we let the standard merge algorithm run
          let mergedValue = secondValue || firstValue;
          if (mergedValue === null) {
            mergedValue = undefined;
          }

          return mergedValue;
        },
      ),
    {},
  );
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item: any): boolean {
  return !!item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param sources
 */
export function mergeDeep(target: Record<string, any>, ...sources: Record<string, any>[]): Record<string, any> {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}
