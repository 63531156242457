import React, { FC } from 'react';
import MuiButton from '@material-ui/core/Button';

import { ButtonProps } from './types';
import { RefreshSpinner } from '../../atoms';

import useStyles from './style';

const Button: FC<ButtonProps> = (props) => {
  const classes = useStyles(props);

  const { loading, label, raised, variant, link, href, startIcon, ...rest } = props;

  return (
    <MuiButton
      {...rest}
      variant={raised ? 'contained' : variant} // For backward compatibility with older SimpleButton component
      href={href || link} // For backward compatibility with older SimpleButton component
      startIcon={loading ? <RefreshSpinner size={24} classes={{ root: classes?.startIcon }} /> : startIcon}
    >
      {label}
    </MuiButton>
  );
};

export default Button;
