import React, { FC, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';

import { config } from '../../../config';

import { Button } from '../../../../fe_common/client/components/inputs';
import { TextDisplay } from '../../../../fe_common/client/components/display';
import { Container } from '../../../../fe_common/client/components/utility/Container';
import { svgs } from '../../../../fe_common/client/app/svg';
import { STATIC_ASSETS_CDN_URL } from '../../../../fe_common/client/app/consts';
import { Environment } from '../../../../fe_common/client/app/types';

import useStyles from './style';

interface ErrorPageProps {
  error: unknown;
  onGotoHomepageClick: () => void;
}

const ErrorPage: FC<ErrorPageProps> = (props) => {
  const { error, onGotoHomepageClick } = props;

  const theme = useTheme();
  const classes = useStyles(props);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const myAudioPlayer = useRef<HTMLAudioElement>(null);

  // if the user will interact with the page within 2sec he will hear a sound
  useEffect(() => {
    try {
      window.setTimeout(() => {
        if (myAudioPlayer.current && ![Environment.PORKY, Environment.DORKY].includes(config.env)) {
          myAudioPlayer.current.volume = 0.5;
          myAudioPlayer.current.play();
        }
      }, 2000);
    } catch (e) {
      // do nothing
    }
  }, []);

  return (
    <Container classes={{ container: classes.root }}>
      <Grid container justifyContent="space-between" className={classes.errorContainer}>
        <Grid item xs={12} md={6} className={classes.errorMessageContainer}>
          <TextDisplay variant={isMobile ? 'h2' : 'h1'} bold gutterBottom>
            Oh no! Oh no!
          </TextDisplay>
          <TextDisplay variant={isMobile ? 'h6' : 'h5'}>
            Something went wrong, we reported the error and will fix it ASAP.
          </TextDisplay>
          <Accordion elevation={0} className={classes.errorDetailsContainer}>
            <AccordionSummary className={classes.errorDetailsSummary} expandIcon={<ExpandMoreIcon />}>
              <TextDisplay variant="subtitle1">Error details</TextDisplay>
            </AccordionSummary>
            <AccordionDetails className={classes.errorDetails}>
              <pre>
                <span className={classes.errorMessage}>Page Crashed With Error: </span>
                {error && error.toString()}
              </pre>
            </AccordionDetails>
          </Accordion>
          <Button
            label="Go back to homepage"
            onClick={onGotoHomepageClick}
            link="/"
            color="secondary"
            variant="contained"
            size="large"
            className={classes.goToHomepageButton}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.errorImageContainer}>
          <img src={svgs.errorCat} height="auto" alt="Error" className={classes.errorCat} />
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <audio id="ohNoAudio" ref={myAudioPlayer} loop={false}>
            <source src={`${STATIC_ASSETS_CDN_URL}/audio/Capone_-_Oh_No_Oh_No_cut.mp3`} type="audio/mp3" />
          </audio>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ErrorPage;
