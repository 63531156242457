import React from 'react';
import { FallbackRender } from '@sentry/react';

import ErrorPage from './ErrorPage';

import { clientLogger } from '../../../../fe_common/client/services/logger';

const UnexpectedErrorPage: FallbackRender = (props) => {
  const { error, resetError } = props;

  // @ts-ignore
  clientLogger.error(`Error in cpanel:\n\n${JSON.stringify(error.toString(), null, 2)}`);

  const onGotoHomepageClickHandler = () => {
    // When resetError() is called it will remove the Fallback component
    // and render the Sentry ErrorBoundary's children in their initial state
    resetError();
  };

  return <ErrorPage error={error} onGotoHomepageClick={onGotoHomepageClickHandler} />;
};

export default UnexpectedErrorPage;
