/* eslint-disable no-undef */

const Joi = require('joi');

const { Environment } = require('../fe_common/client/app/types');

// Returns the current app-environment indicator.
function getCurrentEnvironmentName() {
  return __PERFPIE_ENVIRONMENT__ || Environment.DORKY;
}

let configValues = {
  PERFPIE_ENVIRONMENT: __PERFPIE_ENVIRONMENT__,
  NODE_ENV: __NODE_ENV__,
  EXTERNAL_GRAPHQL_URL: __EXTERNAL_GRAPHQL_URL__,
  FILESTACK_API_KEY: 'Ae1zyGYnpTHGv3OPbAEkoz',
  ENABLE_SENTRY: true,
  SENTRY_ID: 'https://33679e57041e41c283bc95fd08cd4eae@o226092.ingest.sentry.io/6117854',
  SENTRY_ORG_SLUG: 'selectom',
  ENABLE_MIXPANEL_SESSION_REPLAY: false,
  MIXPANEL_PROJECT_TOKEN: '2a0803e41fa09f1f9a2d16c9de299844', // development key
  isDorky: false,
  isStorky: false,
  isPorky: false,
  isTorky: false,
};

const currentEnvironment = getCurrentEnvironmentName();

if (currentEnvironment === Environment.PORKY) {
  configValues = {
    ...configValues,
    DOMAIN: 'mayple.com',
    MAYPLE_AS_A_MARKETER_ID: 664,
    isPorky: true,
    SEGMENT_ID: 'CnhmC7O19FvRUQlKYp60MfNeQTpOjJ1q',
    HUBSPOT_PORTAL_ID: '4292856',
    ENABLE_MIXPANEL_SESSION_REPLAY: true,
    MIXPANEL_PROJECT_TOKEN: '0ff931f7c0bb211145d4e7af43b4bc2b',
  };
} else if (currentEnvironment === Environment.STORKY) {
  configValues = {
    ...configValues,
    DOMAIN: 'selectom.wtf',
    MAYPLE_AS_A_MARKETER_ID: 249,
    isStorky: true,
    SEGMENT_ID: 'jilw4LzJR52AKs7ivzAO8lFPDkfseKBT',
    HUBSPOT_PORTAL_ID: '5249128',
  };
} else if (currentEnvironment === Environment.TORKY) {
  configValues = {
    ...configValues,
    DOMAIN: 'selectom.to',
    MAYPLE_AS_A_MARKETER_ID: 249,
    isTorky: true,
    SEGMENT_ID: 'q5g0LbT62iCi6nlMZ84R9xKTN468VTmT',
    HUBSPOT_PORTAL_ID: '20738639',
  };
} else if (currentEnvironment === Environment.DORKY) {
  configValues = {
    ...configValues,
    DOMAIN: 'selectom.rocks',
    MAYPLE_AS_A_MARKETER_ID: 249,
    isDorky: true,
    SEGMENT_ID: 'oO758j72xt6UFaTOadd6aOOKrqr24dPd',
    ENABLE_SENTRY: false,
    HUBSPOT_PORTAL_ID: '5560072',
  };
}

const clientSchema = Joi.object({
  PERFPIE_ENVIRONMENT: Joi.string()
    .allow(Environment.DORKY, Environment.TORKY, Environment.STORKY, Environment.PORKY)
    .required(),
  NODE_ENV: Joi.string().allow('development', 'production').required(),
  EXTERNAL_GRAPHQL_URL: Joi.string().required(),
  DOMAIN: Joi.string().required(),
  MAYPLE_AS_A_MARKETER_ID: Joi.number().integer().required(), // The marketer id of the Mayple marketer entity
  FILESTACK_API_KEY: Joi.string().required(),
  SEGMENT_ID: Joi.string().required(),
  HUBSPOT_PORTAL_ID: Joi.string().required(),
  ENABLE_SENTRY: Joi.bool().required(),
  SENTRY_ID: Joi.string().required(),
  SENTRY_ORG_SLUG: Joi.string().required(),
  ENABLE_MIXPANEL_SESSION_REPLAY: Joi.bool().required(),
  MIXPANEL_PROJECT_TOKEN: Joi.string().required(),
})
  .unknown()
  .required();

const { error, value: clientVars } = clientSchema.validate(configValues);

if (error) {
  throw new Error(`Config validation client error: ${error.message}`);
}

const clientConfig = {
  env: clientVars.PERFPIE_ENVIRONMENT,
  nodeEnv: clientVars.NODE_ENV,
  maamId: clientVars.MAYPLE_AS_A_MARKETER_ID,
  filestackApiKey: clientVars.FILESTACK_API_KEY,
  graphqlUrl: clientVars.EXTERNAL_GRAPHQL_URL,
  domain: clientVars.DOMAIN,
  enableSentry: clientVars.ENABLE_SENTRY,
  sentryOrgSlug: clientVars.SENTRY_ORG_SLUG,
  sentryClientId: clientVars.SENTRY_ID,
  hubspotPortalId: clientVars.HUBSPOT_PORTAL_ID,
  isPorky: clientVars.isPorky,
  isStorky: clientVars.isStorky,
  isTorky: clientVars.isTorky,
  isDorky: clientVars.isDorky,
  segmentClientId: clientVars.SEGMENT_ID,
  enableMixpanelSessionReplay: clientVars.ENABLE_MIXPANEL_SESSION_REPLAY,
  mixpanelProjectToken: clientVars.MIXPANEL_PROJECT_TOKEN,
  serverBaseUrl: '',
};

if (__PERFPIE_ENVIRONMENT__ === Environment.DORKY && __NODE_ENV__ === 'development') {
  clientConfig.serverBaseUrl = 'http://cpanel.selectom.rocks:20002';
}

export const config = clientConfig;
